import React from 'react';

export const ButtonFill = ({children}) => {
  return (
    <button className="bg-primary ease-in-out delay-150 jakarta-sans text-white px-3 md:px-6 py-2 md:py-3 hover:-translate-y-1 hover:scale-110  rounded-full hover:bg-primaryDark transition duration-300">
      {children}
    </button>
  );
};

export default ButtonFill;
