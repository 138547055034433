import React from 'react';

export const ButtonOutline = ({ children, className }) => {
  return (
    <button className={`${className} ease-in-out delay-150  hover:-translate-y-1 hover:scale-110   font-semibold border border-primary text-primary px-3 md:px-6 py-2 md:py-3 rounded-full transition duration-300`}>
      {children} 
    </button>
  );
};

export default ButtonOutline;
