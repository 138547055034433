import React from 'react'
export const ProductCard = ({ photo, title, delay }) => {

  return (
    <div className='flex flex-col' data-aos="fade-up"
      data-aos-anchor-placement="center-bottom"
      data-aos-offset="50"
      data-aos-delay={delay}
      >
      <img src={photo} className='transition ease-in-out delay-150 hover:-translate-y-6 hover:scale-100 duration-300' />
      <h1 className='text-[32px] text-center my-3 font-[500]'>{title}</h1>
      <p className='text-[16px] text-center text-[#808080]'>Lorem ipsum dolor sir amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
    </div>
  )
}
