import React, { useState, useRef, useEffect } from 'react';
import { PiArrowCircleLeftThin, PiArrowCircleRightThin } from 'react-icons/pi'; // Import your icons
import { TestiCard } from './TestiCard'; // Import your TestiCard component

const TestimonialSlider = () => {
  const testimonials = [
    { desk: "Finding the right hair care used to be a nightmare for me. Thanks to Lusttres, I have a custom solution that keeps my scalp healthy!", author: "Emily S" },
    { desk: "I’ve tried countless hair products, but I find Lusttres works the best for me as it’s tailored to exactly what I need.", author: "Fauzan" },
    { desk: "Lusttres has completely changed my hair care routine. Highly recommend!", author: "Dito M." },
    { desk: "Finding the right hair care used to be a nightmare for me. Highly recommend!", author: "Astiya" },
    { desk: "I’ve tried countless hair products, but I find Lusttres works the best for me as it’s tailored to exactly what I need.", author: "Amelia" },
  ];

  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    const updateItemsPerPage = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setItemsPerPage(1);
      } else {
        setItemsPerPage(3);
      }
    };

    const updateCardWidth = () => {
      if (sliderRef.current) {
        setCardWidth(sliderRef.current.offsetWidth / itemsPerPage);
      }
    };

    updateItemsPerPage();
    updateCardWidth();

    window.addEventListener('resize', updateItemsPerPage);
    window.addEventListener('resize', updateCardWidth);

    return () => {
      window.removeEventListener('resize', updateItemsPerPage);
      window.removeEventListener('resize', updateCardWidth);
    };
  }, [itemsPerPage]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, testimonials.length - itemsPerPage));
  };

  const isPrevDisabled = currentIndex === 0;
  const isNextDisabled = currentIndex >= testimonials.length - itemsPerPage;

  return (
    <section className='md:mx-[3rem] mx-[1rem] p-[2rem] md:p-[4rem] rounded-[28px] bg-primary relative'>
      <div className='flex md:flex-row flex-col justify-between items-end'>
        <h1 data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          data-aos-offset="100"
          className='text-[40px] md:text-[84px] text-white frank font-[500] md:mr-[20rem] leading-[3rem] md:leading-[6rem]'>
          What they say about us...
        </h1>
        <div className='flex  text-white'>
          <PiArrowCircleLeftThin
            size={70}
            onClick={!isPrevDisabled ? handlePrev : undefined}
            className={`cursor-pointer ${isPrevDisabled ? 'disable-arrow' : ''}`}
          />
          <PiArrowCircleRightThin
            size={70}
            onClick={!isNextDisabled ? handleNext : undefined}
            className={`cursor-pointer ${isNextDisabled ? 'disable-arrow' : ''}`}
          />
        </div>
      </div>

      <div className="container mx-auto md:mt-14 mt-8 overflow-hidden" ref={sliderRef}>
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * cardWidth}px)`,
            width: `${testimonials.length * cardWidth}px`,
          }}
        >
          {testimonials.map((testimonial, index) => (
            <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 px-2 mb-4">
              <TestiCard desk={testimonial.desk} author={testimonial.author} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSlider;
