export const data = [
  {
    question: "Apa tipe kulit kepala kamu?",
    background: 1,
    isFullWidth: false,
    type: "option",
    options: [
      {
        id: 0,
        title: "Kering",
        desc: "Kulit kepala terasa kering dan bersisik",
        img: "quiz/tipe-kering.png",
      },
      {
        id: 1,
        title: "Normal",
        desc: "Tidak terlalu kering ataupun berminyak",
        img: "quiz/tipe-normal.png",
      },
      {
        id: 2,
        title: "Berminyak",
        desc: "Terasa lepek dan tidak mengembang",
        img: "quiz/tipe-berminyak.png",
      },
    ],
  },
  {
    question: "Ketebalan batang rambut kamu?",
    desc : "Pilih opsi dibawah ini yang paling menggambarkan caranya dengan menggulung sehelai rambut dengan dua jari dan liat ketebalannya",
    background: 1,
    isFullWidth: false,
    type: "option",
    options: [
      {
        id: 0,
        title: "Tebal",
        desc: "Kamu memiliki tipe rambut tebal",
        img: "quiz/rambut-tebal.png",
      },
      {
        id: 1,
        title: "Normal",
        desc: "Kamu memiliki rambut yang tidak tebal juga tidak tipis",
        img: "quiz/rambut-normal.png",
      },
      {
        id: 2,
        title: "Tipis",
        desc: "Kamu memiliki tipe rambut tipis",
        img: "quiz/rambut-tipis.png",
      },
    ],
  },
  {
    question: "Apakah kamu mengalami rambut rontok?",
    background: 1,
    isFullWidth: true,
    type: "option",
    options: [
      {
        id: 0,
        title: "Tidak",
        desc: "Tidak terlalu kering ataupun berminyak",
        img: "quiz/rambut-sehat.png",
      },
      {
        id: 1,
        title: "Iya",
        desc: "Terasa lepek dan tidak mengembang",
        img: "quiz/rambut-rontok.png",
      },
    ],
  },
  {
    question: "Apakah kamu memakai hijab dalam keseharian?",
    background: 2,
    isFullWidth: false,
    type: "option",
    options: [
      { id: 0, title: "Ya", desc: "", img: "quiz/yes.png" },
      { id: 1, title: "Tidak", desc: "", img: "quiz/no.png" },
    ],
  },
  {
    question: "Apakah kamu sedang mewarnai rambut saat ini?",
    background: 2,
    isFullWidth: false,
    type: "option",
    options: [
      { id: 0, title: "Ya", desc: "", img: "quiz/yes.png" },
      { id: 1, title: "Tidak", desc: "", img: "quiz/no.png" },
    ],
  },
  {
    question: "Seberapa sering kamu menggunakan alat styling rambut?",
    background: 2,
    isFullWidth: false,
    type: "option",
    options: [
      { id: 0, title: "Setiap hari", desc: "", img: "quiz/setiap-hari.png" },
      {
        id: 1,
        title: "1-2 kali seminggu",
        desc: "",
        img: "quiz/seminggu.png",
      },
      {
        id: 2,
        title: "Hampir tidak pernah",
        desc: "",
        img: "quiz/no-1.png",
      },
    ],
  },
  {
    question: "Kamu lebih sering melakukan aktivitas dimana?",
    background: 1,
    isFullWidth: true,
    type: "option",
    options: [
      {
        id: 0,
        title: "Indoor",
        desc: "(Ruangan dengan AC)",
        img: "quiz/indoor.png",
      },
      {
        id: 1,
        title: "Outdoor",
        desc: "(Ruangan tanpa AC)",
        img: "quiz/outdoor.png",
      },
    ],
  },
  {
    question: "Transportasi yang kamu gunakan untuk beraktivitas?",
    background: 1,
    isFullWidth: true,
    type: "option",
    options: [
      { id: 0, title: "Mobil pribadi", desc: "", img: "quiz/mobil.png" },
      { id: 1, title: "Motor", desc: "", img: "quiz/motor.png" },
      {
        id: 2,
        title: "Transportasi umum",
        desc: "",
        img: "quiz/trans-umum.png",
      },
    ],
  },
  {
    question: "Pilih wangi yang menggambarkan kamu banget!",
    background: 3,
    isFullWidth: true,
    type: "option",
    options: [
      {
        id: 0,
        title: "Vivid",
        desc: "Orange blossom, rose, jasmine, musk, iris",
        img: "quiz/vivid.png",
      },
      {
        id: 1,
        title: "Sassy",
        desc: "Bergamont, vanilla, pink pepper, jasmine, rose",
        img: "quiz/sassy.png",
      },
      {
        id: 2,
        title: "Money Bag",
        desc: "Sandalwood, patchouli, musk, orange, green apple",
        img: "quiz/money-bag.jpg",
      },
      {
        id: 3,
        title: "Happy Go Lucky",
        desc: "Citrus, jasmine, water lily, sandalwood, rose",
        img: "quiz/happy-go-lucky.png",
      },
    ],
  },
  {
    question: "Hairgoals rambut impian kamu (Pilih 3)",
    background: 3,
    type: "checkbox",
    options: [
      { id: 0, title: "Rambut tidak rontok", desc: "", img: "" },
      { id: 1, title: "Rambut bervolume", desc: "", img: "" },
      { id: 2, title: "Kulit kepala tidak kering", desc: "", img: "" },
      { id: 3, title: "Warna cat rambut tahan lama", desc: "", img: "" },
      { id: 4, title: "Rambut tidak kering", desc: "", img: "" },
      { id: 5, title: "Ujung rambut tidak bercabang", desc: "", img: "" },
      { id: 6, title: "Rambut sehat dan berkilau", desc: "", img: "" },
      { id: 7, title: "Rambut cepat tumbuh kembali", desc: "", img: "" },
    ],
  },
];


