import { Link, useLocation } from 'react-router-dom';
import Logo from '../images/logo-lusttress.png';
import React, { useState, useEffect } from 'react';
import Lsearch from '../svg/ion_search-outline.svg';
import Lshop from '../svg/basil_shopping-bag-outline.svg';
import Aos from 'aos';

const Navbar = () => {
  Aos.init();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [isShrunk, setIsShrunk] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsShrunk(true);
    } else {
      setIsShrunk(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <nav
      className={`bg-white py-4 md:py-6 px-12 shadow-sm sticky top-0 z-50 transition-all duration-300 ease-in-out ${isShrunk || location.pathname === "/quiz-lusttress" ? 'py-4 px-8 transform translate-y-[-10px]' : 'transform translate-y-0'
        }`}
    >
      <div className="container mx-auto flex justify-between items-center">

        <img src={Logo} alt="Logo" className={`h-10 transition-all duration-300 ease-in-out ${isShrunk ? 'h-8' : 'h-10'}`} />
        <div className="hidden md:flex font-normal space-x-12 flex-grow justify-center">
          <Link to="/" className={`text-gray-800 hover:text-gray-600 transition duration-300 ${isActive('/') ? 'text-primary font-bold' : ''}`}>About Us</Link>
          <Link to="/how-to" className={`text-gray-800 hover:text-gray-600 transition duration-300 ${isActive('/how-to') ? 'text-primary font-bold' : ''}`}>How to Use</Link>
          <Link to="/quiz-lusttress" className={`text-gray-800 hover:text-gray-600 transition duration-300 ${isActive('/quiz-lusttress') ? 'text-primary font-bold' : ''}`}>Take Quiz</Link>
          <Link to="/#" className={`text-gray-800 hover:text-gray-600 transition duration-300`}>Shop</Link>
          <Link to="/#" className={`text-gray-800 hover:text-gray-600 transition duration-300`}>Contact Us</Link>
        </div>

        <div className="flex items-center relative">
          <button
            onClick={toggleSearch}
            className="text-gray-800 hidden md:block hover:text-gray-600 focus:outline-none transition duration-300"
          >
            <img src={Lsearch} alt="search" className={`w-6 transition-all duration-300 ease-in-out ${isShrunk ? 'w-5' : 'w-6'}`} />
          </button>
          {isSearchOpen && (
            <div className="absolute top-full mt-2 right-2 bg-white border border-green-500 rounded-md shadow-lg z-50">
              <input
                type="search"
                className="p-2 w-[24rem] border-none focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md"
                placeholder="Search..."
              />
            </div>
          )}
          <button className="text-gray-800 ml-8 hidden md:block hover:text-gray-600 focus:outline-none transition duration-300">
            <img src={Lshop} alt="shop" className={`w-6 transition-all duration-300 ease-in-out ${isShrunk ? 'w-5' : 'w-6'}`} />
          </button>
          <div className="md:hidden ml-4">
            <button
              onClick={toggleMenu}
              className="text-gray-800 hover:text-gray-600 focus:outline-none transition duration-300"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`md:hidden  transition-all duration-500 ease-in-out transform ${isOpen ? 'opacity-100 max-h-screen' : 'opacity-0 max-h-0 overflow-hidden'}`}>
        <a href="/" className={`block px-4 py-2 text-gray-800 hover:bg-primary rounded-md hover:text-white hover:text-gray-600 transition duration-300 ${isActive('/') ? 'bg-primary text-white' : ''}`}>About Us</a>
        <a href="/how-to" className={`block px-4 py-2 text-gray-800 hover:bg-primary rounded-md hover:text-white hover:text-gray-600 transition duration-300 ${isActive('/how-to') ? 'bg-primary text-white' : ''}`}>How to Use</a>
        <a href="/quiz-lusttress" className={`block mt-2 px-4 py-2 text-gray-800 hover:bg-primary rounded-md hover:text-white hover:text-gray-600 transition duration-300 ${isActive('/quiz-lusttress') ? 'bg-primary text-white' : ''}`}>Take Quiz</a>
        <a href="/" className={`block mt-2 px-4 py-2 text-gray-800 hover:bg-primary rounded-md hover:text-white hover:text-gray-600 transition duration-300`}>Shop</a>
        <a href="/" className={`block mt-2 px-4 py-2 text-gray-800 hover:bg-primary rounded-md hover:text-white hover:text-gray-600 transition duration-300`}>Contact Us</a>
      </div>
    </nav>
  );
};

export default Navbar;
