import React from 'react'
import Navbar from '../components/Navbar'

export const AboutPage = () => {
  return (
    <div>
       <Navbar />
    </div>
  )
}
