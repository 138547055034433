import React from 'react'
import ig from '../svg/ri_instagram-fill.svg'
import wa from '../svg/ri_whatsapp-fill.svg'
export const Footer = () => {
  return (
    <div className='bg-[#184736] md:p-20 p-10'>
      <div className='container  '>
        <div className='flex md:flex-row flex-col gap-5 md:gap-20'>
          <ul className='footer-item'>
            <li>FAQs</li>
            <li>Contact Us</li>
            <li>Order</li>
          </ul>
          <ul className='footer-item'>
            <li>About Us</li>
            <li>Testimonial</li>
          </ul>
        </div>
        <div className='flex md:flex-row flex-col justify-between items-start nd:items-end align-bottom'>
          <div className='flex mt-24'>
            <a href='#'><img src={ig} /></a>
            <a href='#'><img className='ml-4' src={wa} /></a>
          </div>
          <ul className='footer-item-cw flex md:flex-row flex-col gap-5'>
            <li>
              © LUSTTRES 2024
            </li>
            <li>
              Privacy policy
            </li>
            <li>
              Terms of use
            </li>
          </ul>
        </div>
      </div>

    </div>
  )
}
