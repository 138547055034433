import { data } from "../data";
import React, { useEffect, useState } from "react";
import bg1 from "../images/quiz/bg-1.png";
import bg2 from "../images/quiz/bg-2.png";
import bg3 from "../images/quiz/bg-3.png";
import QuestionCard from "../components/QuestionCard";
import NavbarQuiz from "../components/NavbarQuiz";

export const QuizPage = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);

  let bgImg;

  if (data[currentQuestion]?.background === 1) {
    bgImg = bg1;
  } else if (data[currentQuestion]?.background === 2) {
    bgImg = bg2;
  } else {
    bgImg = bg3;
  }

  useEffect(() => {
    document.title = "Quiz | Lusttress";
  }, [])
  return (
    <div
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className="md:h-screen h-auto responsive-bg"
    >
      <NavbarQuiz />
      <QuestionCard
        currentQuestion={currentQuestion}
        setCurrentQuestion={setCurrentQuestion}
      />
    </div>
  );
};

export default QuizPage;
