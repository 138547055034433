import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/style.css'
import './tailwind.css';

import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { HomePage } from './pages/HomePage';
import { AboutPage } from './pages/AboutPage';
import { QuizPage } from './pages/QuizPage';
import  ResultPage  from './pages/ResultPage';
import HowToPage from './pages/HowToPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/quiz-lusttress",
    element: <QuizPage />,
  },
  {
    path: "/quiz-result",
    element: <ResultPage />,
  },
  {
    path: "/how-to",
    element: <HowToPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
