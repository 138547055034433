import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import product from '../images/SKU.png';
import product2 from '../images/product-2.png'
import product3 from '../images/product-3.png'
import ButtonFill from '../components/ButtonFill';
import ButtonOutline from '../components/ButtonOutline';
import svgicon1 from '../svg/mdi_flower-lotus.svg'
import svgtf from '../svg/tflower.svg'
import svgbf from '../svg/bflower.svg'
import svglf from '../svg/lflower.svg'
import arrRight from '../svg/arr-right.svg'
import { ProductCard } from '../components/ProductCard';
import TestimonialSlider from '../components/TestimonialSection';
import i1 from '../images/i-1.png'
import i2 from '../images/i-2.png'
import i3 from '../images/i-3.png'
import i4 from '../images/i-4.png'
import i5 from '../images/i-5.png'
import i6 from '../images/i-6.png'
import i7 from '../images/i-7.png'
import post1 from '../images/post-1.png'
import post2 from '../images/post-2.png'
import post3 from '../images/post-3.png'

import { Footer } from '../components/Footer';
import Aos from 'aos';

export const HomePage = () => {
  useEffect(() => {

    document.title = "Welcome To Lusttress";

  }, [])
  return (
    <div>
      <Navbar />
      <section className="bg-section-1">
        <div className="flex flex-col ml-[2rem] md:ml-[7rem] mb-[5rem] md:flex-row items-center">
          <img src={product} data-aos="zoom-in" data-aos-offset="50" data-aos-duration="1000" alt="Sample" className="w-[42rem] block md:hidden" />
          <div className="text-section md:w-1/2">
            <h2 className="md:text-[56px] text-[30px] jakarta-sans mb-4 text-primary " data-aos="fade-right" >#JUSTFORYOU</h2>
            <p data-aos="fade-right"
              data-aos-duration="1000" className="font-bold text-dark leading-[3rem] md:leading-[4rem] mb-8 frank md:text-[60px] text-[40px]">
              1st Personalized Shampoo in Indonesia
            </p>
            <ButtonFill data-aos="fade-up">Start Shopping</ButtonFill>
            <ButtonOutline className={`md:ml-5 ml-3`}>See Products</ButtonOutline>
          </div>
          <img src={product} data-aos="zoom-in" data-aos-offset="50" data-aos-duration="1000" alt="Sample" className="w-[42rem] md:block hidden" />
        </div>
      </section>

      <section className='satu bg-[#184736]'>
        <div className="flex flex-col md:flex-row items-center">
          <div className="image-section md:w-[35rem] mt-12 hidden md:block">
            <img src={product2} alt="Sample" className="w-cover" />
          </div>
          <div className="text-section md:w-1/2  py-4 pl-3">
            <p className="text-[20px] md:text-[28px] font-[300] mt-10 text-white"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-offset="100"
            >
              At Lusttres, we pride ourselves on our meticulous process of formulating high quality products. Whether your hair is curly, straight, wavy, or somewhere in between, we’re here to provide you with products that reflect and enhance your natural beauty.
            </p>
          </div>
          <div className="image-section md:w-[35rem] mt-6 md:mt-12 block md:hidden">
            <img src={product2} alt="Sample" className="w-cover" />
          </div>
        </div>
      </section>

      <section className="bg-[#FAFAFA] relative pb-[5rem] md:pb-[10rem]">
        <img src={svgtf} className="absolute hidden md:block top-0 left-0" alt="SVG" />
        <div className="container flex flex-col md:flex-row  items-center md:items-end">
          <div className="image-section mt-[10rem] w-[300px] md:w-[400px] md:ml-[8rem] flex items-center justify-center">
            <img src={product3} alt="Sample" className="w-cover" />
          </div>

          <div className="text-section p-8 md:w-1/2 md:ml-[3rem] ">
            <img data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-offset="50"
              src={svgicon1} className='w-[5rem] md:w-[6rem]' alt="SVG Icon" />
            <h1
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-offset="120"

              className="md:text-[60px] text-[40px] mt-4 md:mt-8 text-dark frank md:leading-[4rem] leading-[3rem] font-semibold">
              Find your perfect hair care match
            </h1>
            <p data-aos="fade-zoom-in"
              className="text-dark text-[18px] font-[500] md:my-8 mb-3 md:mb-8">
              Ready for amazing hair days? Take our quick quiz to discover the ultimate hair care routine tailored just for you and get personalized recommendations to make your hair shine like never before.
            </p>
          <a href='/quiz-lusttress'><ButtonFill>Take Our Quiz</ButtonFill></a>
          </div>
        </div>
        <img src={svgbf} className='float-right  w-[15rem] md:w-[20rem] md:-mt-[7rem] -mt-[10rem]' alt="SVG" />

      </section>
      <section className='relative pt-[2rem] md:pt-[7rem] bg-[#FAFAFA] md:px-20'>
        <img src={svglf} alt='flower' className='absolute top-[25rem] left-0 z-0' />
        <div className='relative z-10 flex md:flex-row flex-col items-center w-full justify-between'>
          <div className='title'>
            <h1
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-offset="100"

              className='text-[60px] mb-[2rem] md:mb-0 md:text-[70px] frank font-semibold leading-[3rem] md:leading-[5rem]'>
              Discover Our <br />
              <span className='text-primary'>Products</span>
            </h1>
          </div>
          <ButtonOutline className={`flex items-center gap-2 border-[1.4px]`}>
            Browse More Products <img src={arrRight} />
          </ButtonOutline>

        </div>
        <div className="container mx-auto mt-14 px-2 mb-20 relative z-10">
          <div className="grid gap-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
            <ProductCard delay="100" photo={post1} title="Hair Booster" />
            <ProductCard delay="120" photo={post2} title="Conditioner Base" />
            <ProductCard delay="150" photo={post3} title="Shampoo Base" />
          </div>
        </div>
      </section>

      <TestimonialSlider />
      <section className='relative mt-[3rem] md:mt-[10rem] mb-[10rem] bg-[#FAFAFA]'>
        <div className='relative-iklan'>
          <div className='relative z-10 flex flex-col md:flex-row items-center image-iklan'>
            <img src={i1} className='w-[300px] h-[400px] object-cover transition ease-in-out delay-150 duration-300 hover:scale-110' alt="Image 1" />
            <img src={i2} className='w-[300px] h-[400px] mx-6 object-cover transition ease-in-out delay-150 duration-300 hover:scale-110' alt="Image 2" />
            <img src={i3} className='w-[300px] h-[400px] mr-6 object-cover transition ease-in-out delay-150 duration-300 hover:scale-110' alt="Image 3" />
            <img src={i4} className='w-[300px] h-[400px] object-cover transition ease-in-out delay-150 duration-300 hover:scale-110' alt="Image 4" />
          </div>
          <div className='relative z-10 flex flex-col md:flex-row items-center mt-6 image-iklan'>
            <img src={i5} className='w-[300px] h-[400px] object-cover transition ease-in-out delay-150 duration-300 hover:scale-110' alt="Image 5" />
            <img src={i6} className='w-[300px] h-[400px] mx-6 object-cover transition ease-in-out delay-150 duration-300 hover:scale-110' alt="Image 6" />
            <img src={i7} className='w-[300px] h-[400px] object-cover transition ease-in-out delay-150 duration-300 hover:scale-110' alt="Image 7" />
          </div>
        </div>
      </section>

      <Footer />
    </div>

  )
}
