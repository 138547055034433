import React from 'react'

export const TestiCard = ({ desk, author }) => {
  return (
    <div className={`font-[400]  leading-[2rem] font-[400]  border rounded-[16px] border-white text-[18px] md:text-[22px] text-white md:p-7 p-4 text-center`}>
      <span className='text-[30px] font-bold'>“</span>{desk}<span className='text-[32px] font-bold'>”</span>
      <p className=' text-[16px] md:text-[20px] my-2'><i>-{author}</i></p>
    </div>
  )

}
