import React from "react";
import Navbar from "../components/Navbar";
import { Footer } from "../components/Footer";
import ResultComp from "../components/ResultComp";

const ResultPage = () => {
  return (
    <div>
      <Navbar />
      <ResultComp />
      <Footer />
    </div>
  );
};

export default ResultPage;
